<template>
  <div>
    <b-col sm="12" lg="6" xl="6">
      <b-card class="mt-5">
        <b-form @submit.prevent="loginFormSubmit">
          <b-form-group
            id="input-group-1"
            label="E-mailadres"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model="form.username"
              type="text"
              placeholder="E-mailadres"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label="Wachtwoord"
            label-for="input-2"
          >
            <b-input-group class="mt-3">
              <b-form-input
                v-model="form.password"
                :type="showPassword ? 'text' : 'password'"
                placeholder="Wachtwoord"
                required
              ></b-form-input>
              <b-input-group-append>
                <b-button
                  @click="showPasswordToggle()"
                  title="Wachtwoord tonen"
                  variant="primary"
                >
                  <icon-eye v-if="!showPassword" />
                  <icon-eye-slash v-if="showPassword" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

          <b-row>
            <b-col xs="12" sm="6">
              <b-button class="mb-2" type="submit" variant="primary"
                >Inloggen
                <font-awesome-icon
                  v-if="processing"
                  :icon="['fad', 'spinner']"
                  class="fa-spin"
                  far
              /></b-button>
            </b-col>
            <b-col xs="12" sm="6">
              <router-link :to="{ name: 'forgotPassword' }" class="float-right"
                >Wachtwoord vergeten?</router-link
              >
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
  </div>
</template>

<script>
import { login, authenticate } from '@/services/AuthorizationService'
import IconEye from '@/components/icons/IconEye'
import IconEyeSlash from '@/components/icons/IconEyeSlash'

export default {
  components: { IconEyeSlash, IconEye },
  data: function() {
    return {
      showPassword: false,
      processing: false,
      error: '',
      form: {},
      username: '',
      password: '',
      samlURL: `${process.env.VUE_APP_URL}/api/v2/saml/start?ResponseUrl=${process.env.VUE_APP_FRONTEND_URL}/nl/login`
    }
  },
  computed: {
    routeHash() {
      return this.$route.hash
    },
    token() {
      if (this.routeHash) {
        return this.routeHash.replace('#token=', '')
      }
      return null
    },
    showLogin() {
      // if url contains "https://uitleen-fdmci.hva.nl/"
      if (window.location.href.includes('uitleen-fdmci.hva.nl')) {
        return false
      }

      return true
    }
  },
  async created() {
    if (this.token) {
      this.processing = true
      await authenticate({ token: this.token })
    }
  },
  methods: {
    async loginFormSubmit() {
      this.processing = true

      const result = await login({
        username: this.form.username,
        password: this.form.password
      })

      if (result) {
        this.error = result
      }

      this.processing = false
    }
  }
}
</script>

<style scoped></style>
